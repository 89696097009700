#card1 img {
    max-width: 280px;
    width: 100%;
    height: auto;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
}

#card2 img, #card3 img {
    max-width: 320px;
    width: 100%;
    height: auto;
}

#card4 img, #card5 img, #card6 img, #card7 img{
    max-width: 280px;
    width: 100%;
    height: auto;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
}

.screenshots {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.card {
    max-width: 1400px;
    width: 100%;
    border-radius: 8px;
    background: white;
    box-shadow: 2px 2px 40px #e2e2e2;
    margin-top: 10px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    overflow: auto;
    animation: fade-in;
    animation-duration: 0.6s;
    animation-timing-function: ease;
}

.card-title {
    display: inherit;
    text-align: center;
    font-weight: bold;
    color: #3b3b3b;
    font-size: 35px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: 'Raleway', sans-serif;
}

.card-description {
    font-size: 24px;
    margin: auto;
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
    max-width: 800px;
    font-family: 'Raleway', sans-serif;
}

@keyframes fade-in {
    from {
        opacity: 0;
        position: relative;
        top: 40px;
    }
    to {
        opacity: 1;
        position: relative;
        top: 0;
    }
}