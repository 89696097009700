/* Added for meteor animations */
@keyframes moveMeteor {
    from {
        top: -165px;
        opacity: 0; /* The meteor starts invisible */
    }
    15% {
        opacity: 0.4; /* Quickly fade in during the first 10% of the animation */
    }
    90% {
        opacity: 0.4; /* Quickly fade in during the first 10% of the animation */
    }
    99% {
        opacity: 0; /* Quickly fade in during the first 10% of the animation */
    }
    to {
        top: 110%;
        opacity: 0.0; /* Quickly fade in during the first 10% of the animation */
    }
}

.meteor {
    position: absolute;
    top: -165px;
    animation-name: moveMeteor;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    will-change: transform, opacity;
}

.meteor-1 {
    left: 10%;
    animation-duration: 3.1s;
}

.meteor-2 {
    left: 73%;
    animation-duration: 4.2s;
    animation-delay: 0.5s;
}

.meteor-3 {
    left: 18%;
    animation-duration: 5.4s;
    animation-delay: 1.1s;
}

.meteor-4 {
    left: 27%;
    animation-duration: 3.4s;
    animation-delay: 2.5s;
}

.meteor-5 {
    left: 98%;
    animation-duration: 5s;
    animation-delay: 4.8s;
}

.meteor-6 {
    left: 49%;
    animation-duration: 4.3s;
    animation-delay: 5.6s;
}

.meteor-7 {
    left: 60%;
    animation-duration: 3.4s;
    animation-delay: 1.3s;
}

.meteor-8 {
    left: 69%;
    animation-duration: 4.1s;
    animation-delay: 2.8s;
}

.meteor-9 {
    left: 81%;
    animation-duration: 6.5s;
    animation-delay: 3.45s;
}

.meteor-10 {
    left: 94%;
    animation-duration: 5.3s;
    animation-delay: 4.31s;
}

.meteor-11 {
    left: 3%;
    animation-duration: 3.64s;
    animation-delay: 1.81s;
}

.meteor-12 {
    left: 24%;
    animation-duration: 6.3s;
    animation-delay: 2.81s;
}

.meteor-13 {
    left: 68%;
    animation-duration: 2.3s;
    animation-delay: 3.81s;
}

.meteor-10 {
    left: 95%;
    animation-duration: 3.93s;
    animation-delay: 4.55s;
}

.animated-text-1 {
    width: 100%;
    white-space: nowrap;
    border-right: 5px solid;
    overflow: hidden;
    opacity: 0;
    animation:
        typing 3s forwards,
        cursor .5s step-end infinite alternate;
}

@keyframes cursor {
    50% { border-color: transparent}
}

@keyframes typing {
    0% {
        width: 0;
        opacity: 0;
    }
    1% {
        opacity: 1;
    }
    100% {
        opacity: 1;
        width: 100%;
    }
}