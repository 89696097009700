@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.project-fade-in, .project-fade-in-d1, .project-fade-in-d2, .project-fade-in-d3  {
    opacity: 0;
}

.project-fade-in.visible {
    animation: fadeInUp 1s forwards;
}
.project-fade-in-d1.visible {
    animation: fadeInUp 2s forwards;
    animation-delay: 0.25s;
}
.project-fade-in-d2.visible {
    animation: fadeInUp 2s forwards;
    animation-delay: 0.5s;
}
.project-fade-in-d3.visible {
    animation: fadeInUp 2s forwards;
    animation-delay: 0.75s;
}

.project-fade-in {
    position: relative;
    overflow: hidden; /* Ensures the pseudo-element doesn't overflow */
    display: inline-block; /* Ensures the image doesn't move out of the boundary when scaling */
    transition: transform 0.3s ease;
}

.project-fade-in::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    opacity: 0; /* Initial state - fully transparent */
    transition: opacity 0.3s ease;
    pointer-events: none; /* So it doesn't interfere with clicks on the underlying elements */
}

.project-fade-in:hover::before {
    opacity: 0.2; /* On hover, the white overlay is 20% visible. Adjust as needed */
}
