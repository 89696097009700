@keyframes fadeInLeft {
    0% {
        opacity: 0;
        transform: translateX(100px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeInRight {
    0% {
        opacity: 0;
        transform: translateX(-100px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.fade-in-left, .fade-in-delayed-left, .fade-in-right, .fade-in-delayed-right{
    opacity: 0;
}

.fade-in-left.visible {
    animation: fadeInLeft 0.5s forwards;
}

.fade-in-delayed-left.visible {
    animation: fadeInLeft 0.5s forwards;
    animation-delay: 0.25s;
}

.fade-in-right.visible {
    animation: fadeInRight 0.5s forwards;
}

.fade-in-delayed-right.visible {
    animation: fadeInRight 0.5s forwards;
    animation-delay: 0.25s;
}